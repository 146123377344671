.main-div{
    width:600px;
    margin: 0 auto;
}

@media screen and (max-width: 990px) {
    .main-div{
        width: auto;
        padding: 16px
    }
    
}