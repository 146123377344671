
.main_content_body {
  transition: margin-left .5s;

  padding-top: 64px;
}

@media screen and (min-width: 990px) {
  .main_content_body {margin-left: 250px}
  
}

