.card-width{
    width:266px
}
.card-img-overlay-bottom{
  bottom: unset;
}

@media screen and (max-width: 990px) {
    .card-width{
        width: 320px;
    }
    @media screen and (max-width: 320px) {
        .card-width{
             width: 298px; 
        }
    }
}